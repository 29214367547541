<template>
    <div>
        <div v-if="authUserPermission['end-user-search-material-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>
                </div>
            </base-header>
            <div class="container-fluid mt--7">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col text-center">
                                <h2 class="mb-0">
                                    <b>
                                        {{ tt('simple_search_material') }}
                                    </b>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <!-- PLANT -->
                                <base-input style="margin-top:100px">
                                    <el-select class="custom-margin-top-25px" v-model="data.search.plant" :placeholder="tt('plant_example')" :remote-method="querySearchPlant" :loading="loading" filterable remote reserve-keyword clearable @keyup.enter.native="searchMaterial">
                                        <el-option v-for="pl in plant" :key="pl.plant_code" :label="pl.plant_code +' - '+ pl.description" :value="pl.plant_code"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                            <div class="col-6">
                                <base-input
                                    type="text"
                                    class="form-control-sm"
                                    v-model="data.search.long_description"
                                    :placeholder="tt('long_description_keyword_example')"
                                    size="medium"
                                    @keyup.enter.native="searchMaterial"
                                ></base-input>
                            </div>
                        </div>
                        <base-button type="sm" @click="searchMaterial" class="btn btn-block btn-lg btn-primary pt-3 pb-3" style="margin-top:50px" size="lg">
                            {{ tt('search') }}
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import mcrNumber from '@/services/material/draftList.service';
    import standardItemName from '@/services/dictionary/standardItemName.service';
    import groupClass from '@/services/dictionary/natoGroupClass.service';
    import materialType from '@/services/master/materialType.service';
    import materialGroup from '@/services/master/materialGroup.service';
    import valuationClass from '@/services/master/valuationClass.service';
    import stockType from '@/services/master/stockType.service';
    import manufacture from '@/services/dictionary/manufacture.service';
    import manufactureRef from '@/services/master/manrefType.service';
    import client from '@/services/master/holdingCode.service';
    import companyCode from '@/services/master/companyCode.service';
    import plant from '@/services/master/plantCode.service';
    import location from '@/services/master/locationCode.service';
    import shelf from '@/services/master/shelf.service';
    import bin from '@/services/master/bin.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import scopeOfSupply from '@/services/dictionary/scopeOfSupply.service';
    import unitOfMeasure from '@/services/dictionary/unitOfMeasure.service';
    import catalogStatus from '@/services/master/catalogStatus.service';
    import catalogType from '@/services/master/catalogType.service';
    import updatedBy from '@/services/manage/manageAccount.service';

    export default {
        data() {
            return {
                data: {
                    search: {
                        long_description: '',
                        plant:'',              
                        type: 'search-material',
                    },
                },
                endUserSearchMaterial: [],
                plant: [],
                links:[],
                listSelect:[],
                loading:false,
                selectSearch: {
                    loadTimeout: null
                },
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        methods: {
            searchMaterial() {
                this.$router.push({ path: 'end-user-search-material', query: this.data.search });
            },
            querySearchPlant(query) {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, plant.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.plant = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.plant = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
        }
    };
</script>
<style>
    .width {
        width: 95%; 
    }
    
</style>
